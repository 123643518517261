import { RegisterOptions } from 'react-hook-form';
import { Organization } from '..';
import { ModalStates } from '../../../hooks/useModal';
import { NO_SPACE_ON_START_OR_END } from '../../../utilities/consts';

type OrgActionModalInputRules = {
  name: RegisterOptions;
  organizationType: RegisterOptions;
};

export type OrgActionModalFormFields = {
  name: string;
  active: boolean;
  organizationType: string;
  parentId: string;
};

export type OrganizationActionModalProps = {
  onClose: () => void;
  modalState: ModalStates;
  addOrganization: (
    name: string,
    active: boolean,
    isConsOrganization: boolean,
    parentId?: string
  ) => void;
  editOrganization: (name: string, active: boolean) => void;
  selectedOrganization?: Organization;
};

export const orgActionModalInputRules: OrgActionModalInputRules = {
  name: {
    required: 'Required field',
    pattern: {
      value: NO_SPACE_ON_START_OR_END,
      message: 'Organization name can\'t start or end with a space',
    },
    minLength: {
      value: 2,
      message: 'Name must contain min 2 and max 50 characters',
    },
    maxLength: {
      value: 50,
      message: 'Name must contain min 2 and max 50 characters',
    },
  },
  organizationType: {
    required: 'Required field',
  },
};

export const hcpInfoItems = [
  `If the status of the organization is set to “Inactive”,
  all HCPs from that organization will be set to “Inactive”, too`,
];

export const consumerInfoItems = [
  `Changing the status of the organization to “Inactive”
  does not impact consumer users`,
];

export const NAME_UNAVAILABLE = 'This organization already exists in the system';

export const defaultFieldValues: OrgActionModalFormFields = {
  name: '',
  active: true,
  organizationType: '',
  parentId: '',
};
