/* eslint-disable */
import React from "react";
import Colors from "../../theme/colors";
import * as Icons from "../../assets/icons";
import { IconProps } from ".";

export const SvgIcons = new Map<string, JSX.Element>([
  ["admin-panel-settings", <Icons.AdminPanelSettings />],
  ["arrow-back", <Icons.ArrowBack />],
  ["arrow-forward", <Icons.ArrowForward />],
  ["blood-type", <Icons.BloodType />],
  ["biomarkers", <Icons.Biomarkers />],
  ["check-circle", <Icons.CheckCircle />],
  ["check-circle-outline", <Icons.CheckCircleOutline />],
  ["checked", <Icons.Checked />],
  ["close", <Icons.Close />],
  ["consumers", <Icons.Consumers />],
  ["done", <Icons.Done />],
  ["event", <Icons.Event />],
  ["face", <Icons.Face />],
  ["file-copy", <Icons.FileCopy />],
  ["health-info", <Icons.HealthInfo />],
  ["info", <Icons.Info />],
  ["keyboard-arrow-down", <Icons.KeyboardArrowDown />],
  ["keyboard-arrow-left", <Icons.KeyboardArrowLeft />],
  ["keyboard-arrow-right", <Icons.KeyboardArrowRight />],
  ["keyboard-arrow-up", <Icons.KeyboardArrowUp />],
  ["logout", <Icons.Logout />],
  ["manage-accounts", <Icons.ManageAccounts />],
  ["more-vert", <Icons.MoreVert />],
  ["radio-checked", <Icons.RadioChecked />],
  ["radio-unchecked", <Icons.RadioUnchecked />],
  ["report", <Icons.Report />],
  ["results", <Icons.Results />],
  ["search", <Icons.Search />],
  ["space-dashboard", <Icons.SpaceDashboard />],
  ["stream", <Icons.Stream />],
  ["visibility-off", <Icons.VisibilityOff />],
  ["visibility", <Icons.Visibility />],
  ["warning", <Icons.Warning />],
  ["biotech", <Icons.Biotech />],
  ["accessibility", <Icons.Accessibility />],
  ["question_answer", <Icons.QuestionAnswer />],
  ["qrisk", <Icons.Qrisk />],
  ["photo", <Icons.Photo />],
]);

function Icon({ name, color, size = 22, variant }: IconProps) {
  interface svgProps {
    fill?: string;
    width: number;
    height: number;
    "data-testid": string;
  }
  const props: svgProps = { width: size, height: size, "data-testid": name };
  if (variant) {
    props.fill =
      variant === "active" ? Colors.Icon.Active : Colors.Icon.Disabled;
  } else if (color) {
    props.fill = color;
  }
  const iconElement = SvgIcons.get(name);
  return iconElement ? React.cloneElement(iconElement, props) : null;
}

export default Icon;
