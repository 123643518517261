import React from 'react';
import Typography from '@mui/material/Typography';
import TestResultModalProps from '.';
import { ModalStates } from '../../../hooks/useModal';
import BiologicalResults from './BiologicalResults/BiologicalResults';
import QRISK from './QRISK/QRISK';
import PersonalData from './PersonalData/PersonalData';
import AnsweredQuestions from './AnsweredQuestions/AnsweredQuestions';
import SignalMeasurements from './SignalMeasurements/SignalMeasurements';
import TestImages from './TestImages/TestImages';
import ResultData from './ResultData/ResultData';
import TestMetadata from './TestMetadata/TestMetadata';
import { formatDateToLongFormat } from '../../../utilities/dateTimeUtils';
import { formatStatus, getStatusColor } from '../../../utilities/utils';
import Colors from '../../../theme/colors';
import { Icon } from '../../../components/Icon';
import {
  TestResultDialog,
  ContentContainer,
  TitleContainer,
  Title,
  TestDetailsContainer,
  TestDetailsRowContainer,
  TestDetailsItemLarge,
  TestDetailsItem,
  TestStatusContainer,
  StatusIndicator,
  TestId,
  Date,
  PdfLabel,
  PdfLink,
  PdfPass,
  PersonalDataContainer,
  FeedbackAnswersContainer,
  QriskContainer,
  AnsweredQuestionsContainer,
  TestImagesContainer,
  TestMetadataContainer,
  ResultDataContainer,
  SignalMeasurementsContainer,
  CloseButtonContainer,
  WarningContainer,
  WarningMessage,
  CloseButton,
  InfoFieldLabel,
  ErrorLogsContainer,
} from './styles';
import { QRiskStatus } from './QRISK';
import FeedbackAnswers from './FeedbackAnswers/FeedbackAnswers';
import ErrorLogs from './ErrorLogs/ErrorLogs';

function TestResultModal({ modalState, onClose, selectedResult }: TestResultModalProps) {
  if (selectedResult) {
    const isOpen = modalState !== ModalStates.CLOSED;
    const {
      results: {
        status,
        warningMessage,
        totalCholesterol,
        totalHdlRatio,
        hdl,
        nonHdl,
        ldl,
        triglycerides,
        images,
        resultData,
        signalMeasurements,
        metadata,
        qrisk,
        pdfName,
        pdfPass,
        pdfUrl,
      },
      subjectData,
      errorLogs,
    } = selectedResult;
    const hasResults = status === 'successful' || status === 'warning';

    const biologicalSex = subjectData?.personalAnswers?.find((answer) => answer.questionTitle === 'sex')?.value;

    const hadCardiovascularDisease = subjectData?.answers?.find((answer) => answer.label.includes('cardiovascular disease'))?.value;

    return (
      <TestResultDialog onClose={onClose} open={isOpen} maxWidth="md" fullWidth>
        <ContentContainer>
          <TitleContainer>
            <Title variant="Heading3" color={Colors.Text.Heading}>
              Test Results
            </Title>
          </TitleContainer>
          {warningMessage ? (
            <WarningContainer>
              <Icon name="warning" color={getStatusColor(status)} />
              <WarningMessage variant="BodySmallRegular" color={Colors.Text.Paragraph}>
                {warningMessage}
              </WarningMessage>
            </WarningContainer>
          ) : null}
          <TestDetailsContainer>
            <TestDetailsRowContainer>
              <TestDetailsItemLarge>
                <InfoFieldLabel> Test ID </InfoFieldLabel>
                <TestId>{subjectData.testId}</TestId>
              </TestDetailsItemLarge>
              <TestDetailsItem>
                <InfoFieldLabel> Test Performed </InfoFieldLabel>
                <Date>{formatDateToLongFormat(subjectData.testStartedAt)}</Date>
              </TestDetailsItem>
            </TestDetailsRowContainer>
            <TestDetailsRowContainer>
              <TestDetailsItem>
                <PdfLabel> PDF File </PdfLabel>
                {pdfUrl ? (
                  <PdfLink href={`${pdfUrl}`} target="_blank">{`${pdfName || 'N/A'}`}</PdfLink>
                ) : (
                  <PdfPass>N/A</PdfPass>
                )}
              </TestDetailsItem>
              <TestDetailsItem>
                <InfoFieldLabel> Test Status </InfoFieldLabel>
                <TestStatusContainer>
                  <StatusIndicator sx={{ backgroundColor: getStatusColor(status) }} />
                  <Typography variant="BodySmallRegular" color={Colors.Text.Paragraph}>
                    {formatStatus(status)}
                  </Typography>
                </TestStatusContainer>
              </TestDetailsItem>
            </TestDetailsRowContainer>
            <TestDetailsRowContainer>
              <TestDetailsItem>
                <PdfLabel> PDF Password </PdfLabel>
                <PdfPass>{`${pdfPass || 'N/A'}`}</PdfPass>
              </TestDetailsItem>
            </TestDetailsRowContainer>
          </TestDetailsContainer>
          {hasResults ? (
            <BiologicalResults
              totalCholesterol={totalCholesterol}
              hdl={hdl}
              nonHdl={nonHdl}
              ldl={ldl}
              totalHdlRatio={totalHdlRatio}
              triglycerides={triglycerides}
              isFemale={biologicalSex === 'Female'}
              hadCardiovascularDisease={hadCardiovascularDisease === 'Yes'}
              bmiValue={subjectData.bmi}
            />
          ) : (
            <BiologicalResults
              bmiValue={subjectData.bmi}
              isFemale={biologicalSex === 'Female'}
              hadCardiovascularDisease={hadCardiovascularDisease === 'Yes'}
            />
          )}
          <QriskContainer>
            <QRISK
              heartAge={qrisk.heartAge}
              publishedHeartAge={qrisk.publishedHeartAge}
              scoreHealthy={qrisk.scoreHealthy}
              publishedScoreHealthy={qrisk.publishedScoreHealthy}
              score10Year={qrisk.score10Year}
              publishedScore10Year={qrisk.publishedScore10Year}
              status={qrisk.status as QRiskStatus}
            />
          </QriskContainer>

          <PersonalDataContainer>
            <PersonalData
              personalAnswers={subjectData.personalAnswers}
              nhsNumber={subjectData.nhsNumber}
              odsCode={subjectData.odsCode}
            />
          </PersonalDataContainer>

          <FeedbackAnswersContainer>
            <FeedbackAnswers feedbackAnswers={subjectData.feedbackAnswers} />
          </FeedbackAnswersContainer>

          <AnsweredQuestionsContainer>
            <AnsweredQuestions answers={subjectData.answers} />
          </AnsweredQuestionsContainer>

          <TestImagesContainer>
            <TestImages
              barcodesAndRois={images.barcodesAndRois}
              colorCorrected={images.colorCorrected}
              final={images.final}
              inputCropped={images.inputCropped}
              shadeCorrected={images.shadeCorrected}
              whiteBalanced={images.whiteBalanced}
              linearized={images.linearized}
              rois={images.rois}
              original={images.original}
              sitesBinaryMask={images.sitesBinaryMask}
            />
          </TestImagesContainer>
          <TestMetadataContainer>
            <TestMetadata metadataAnswers={metadata} />
          </TestMetadataContainer>
          <ResultDataContainer>
            <ResultData
              showResultData={hasResults}
              resultData={[
                { value: 'L', ...resultData.l },
                { value: 'a', ...resultData.a },
                { value: 'b', ...resultData.b },
                {
                  value: 'Raw Values',
                  ...resultData.rawValues,
                },
                {
                  value: 'Published Values',
                  ...resultData.publishedValues,
                },
              ]}
            />
          </ResultDataContainer>
          <ErrorLogsContainer>
            <ErrorLogs errorLogs={errorLogs} />
          </ErrorLogsContainer>
          <SignalMeasurementsContainer>
            <SignalMeasurements
              showSignalMeasurements={hasResults}
              meanDeltaE={signalMeasurements.meanDeltaE}
              medianDeltaE={signalMeasurements.medianDeltaE}
              signalMeasurements={[
                {
                  value: 'Signal Over Noise',
                  ...signalMeasurements.overNoise,
                },
                {
                  value: 'Signal Amplitude',
                  ...signalMeasurements.amplitude,
                },
                {
                  value: 'Signal Bandwidth',
                  ...signalMeasurements.bandwidth,
                },
              ]}
            />
          </SignalMeasurementsContainer>
          <CloseButtonContainer>
            <CloseButton variant="outlined" onClick={onClose}>
              Close
            </CloseButton>
          </CloseButtonContainer>
        </ContentContainer>
      </TestResultDialog>
    );
  }
  return null;
}

export default TestResultModal;
