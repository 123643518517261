import React, { useState } from 'react';
import SectionHeader from '../SectionHeader/SectionHeader';
import TestImage from './TestImage/TestImage';
import TestImagesProps from '.';
import { Container, ContentContainer } from './styles';

function TestImages({
  barcodesAndRois,
  colorCorrected,
  final,
  inputCropped,
  shadeCorrected,
  whiteBalanced,
  linearized,
  rois,
  original,
  sitesBinaryMask,
}: TestImagesProps) {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded((value) => !value);
  };

  return (
    <Container>
      <SectionHeader
        icon="photo"
        title="Test Images"
        expandable
        expanded={expanded}
        toggleExpanded={toggleExpanded}
      />
      <ContentContainer>
        {expanded ? (
          <>
            <TestImage imageName="Original (Input) Image" imageUrl={original} />
            <TestImage imageName="Image 1/9 - Input cropped" imageUrl={inputCropped} />
            <TestImage imageName="Image 2/9 - Shade corrected" imageUrl={shadeCorrected} />
            <TestImage imageName="Image 3/9 - White Balanced" imageUrl={whiteBalanced} />
            <TestImage imageName="Image 4/9 - Linearized" imageUrl={linearized} />
            <TestImage imageName="Image 5/9 - Color corrected" imageUrl={colorCorrected} />
            <TestImage imageName="Image 6/9 - Final" imageUrl={final} />
            <TestImage imageName="Image 7/9 - Sites binary mask" imageUrl={sitesBinaryMask} />
            <TestImage imageName="Image 8/9 - Barcodes&Rrois" imageUrl={barcodesAndRois} />
            <TestImage imageName="Image 9/9 - Rois" imageUrl={rois} />
          </>
        ) : null}
      </ContentContainer>
    </Container>
  );
}

export default TestImages;
