import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import Box from '@mui/material/Box';
import Colors from '../../../../theme/colors';

const Container = styled(Box)({
  backgroundColor: Colors.Main.Light,
  borderRadius: '10px',
  overflow: 'hidden',
});

const HeaderTableRow = styled(TableRow)({
  backgroundColor: Colors.Main.Light,
  borderWidth: '0px',
  borderBottomWidth: '2px',
  borderStyle: 'solid',
  borderColor: Colors.Icon.Inactive,
  paddingBottom: '12px',
});

const DeltaEContainer = styled(Box)({
  backgroundColor: Colors.Main.Light,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '16px',
  borderWidth: '0px',
  borderTopWidth: '1px',
  borderStyle: 'solid',
  borderColor: Colors.Icon.Inactive,
  paddingBottom: '12px',
});

const SignalMeasurementsTableContainer = styled(TableContainer)({
  border: 'none',
  borderRadius: 0,
  margin: 0,
});

const SignalMeasurementsTableBody = styled(TableBody)({
  backgroundColor: Colors.Main.Light,
});

export {
  Container,
  SignalMeasurementsTableContainer,
  DeltaEContainer,
  HeaderTableRow,
  SignalMeasurementsTableBody,
};
