import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Colors from '../../../../theme/colors';
import { HeaderTableCell, BodyTableCell } from '../../../../components/Table';
import SectionHeader from '../SectionHeader/SectionHeader';
import SignalMeasurementsProps from '.';
import {
  Container,
  SignalMeasurementsTableContainer,
  HeaderTableRow,
  DeltaEContainer,
  SignalMeasurementsTableBody,
} from './styles';

function SignalMeasurements({
  showSignalMeasurements,
  signalMeasurements,
  meanDeltaE,
  medianDeltaE,
}: SignalMeasurementsProps) {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded((value) => !value);
  };

  return (
    <Container>
      <SectionHeader
        icon="results"
        title={`Signal Measurements${
          showSignalMeasurements ? '' : ' (Unavailable)'
        }`}
        expandable={showSignalMeasurements}
        expanded={expanded}
        toggleExpanded={toggleExpanded}
      />
      {expanded ? (
        <SignalMeasurementsTableContainer>
          <Table>
            <TableHead>
              <HeaderTableRow>
                <HeaderTableCell>Measurement</HeaderTableCell>
                <HeaderTableCell>Triglycerides</HeaderTableCell>
                <HeaderTableCell>HDL</HeaderTableCell>
                <HeaderTableCell>TC</HeaderTableCell>
              </HeaderTableRow>
            </TableHead>

            <SignalMeasurementsTableBody data-testid="signalMeasurementsTableBody">
              {signalMeasurements.map((measurement) => (
                <TableRow key={measurement.value}>
                  <BodyTableCell>{measurement.value}</BodyTableCell>
                  <BodyTableCell>
                    {Number(measurement.triglycerides).toFixed(2)}
                  </BodyTableCell>
                  <BodyTableCell>
                    {Number(measurement.hdl).toFixed(2)}
                  </BodyTableCell>
                  <BodyTableCell>
                    {Number(measurement.totalCholesterol).toFixed(2)}
                  </BodyTableCell>
                </TableRow>
              ))}
            </SignalMeasurementsTableBody>
          </Table>
          <DeltaEContainer>
            <Typography
              color={Colors.Text.Paragraph}
              variant="BodySmallRegular"
            >
              Mean Delta E
            </Typography>
            <Typography
              color={Colors.Text.Paragraph}
              variant="BodySmallRegular"
            >
              {Number(meanDeltaE).toFixed(2)}
            </Typography>
          </DeltaEContainer>
          <DeltaEContainer>
            <Typography
              color={Colors.Text.Paragraph}
              variant="BodySmallRegular"
            >
              Median Delta E
            </Typography>
            <Typography
              color={Colors.Text.Paragraph}
              variant="BodySmallRegular"
            >
              {Number(medianDeltaE).toFixed(2)}
            </Typography>
          </DeltaEContainer>
        </SignalMeasurementsTableContainer>
      ) : null}
    </Container>
  );
}

export default SignalMeasurements;
