import React, { useState } from 'react';
import SectionHeader from '../SectionHeader/SectionHeader';
import ErrorLogsProps from '.';
import { Container, ContentContainer } from './styles';
import ErrorLog from './ErrorLog/ErrorLog';

function ErrorLogs({ errorLogs }: ErrorLogsProps) {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => {
    setExpanded((value) => !value);
  };
  return (
    <Container>
      <SectionHeader
        icon="warning"
        title="Error Logs"
        expandable
        expanded={expanded}
        toggleExpanded={toggleExpanded}
      />
      {expanded && (
        <ContentContainer>
          {errorLogs
            .map(({
              testSubmittedAt, errorType, errorId, images,
            }) => (
              <ErrorLog
                key={errorId}
                timeStamp={testSubmittedAt}
                errorType={errorType}
                imageLink={images.original}
              />
            ))
            .reverse()}
        </ContentContainer>
      )}
    </Container>
  );
}

export default ErrorLogs;
