import React, { useState, useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import usePagination from '../../hooks/usePagination';
import {
  createOrganization,
  filterOrganizations,
  updateOrganization,
} from '../../api/organization/organization';
import { Organization, OrganizationToastTitles } from '.';
import OrganizationActionModal from './OrganizationActionModal/OrganizationActionModal';
import { PageContainer, FiltersContainer, AddButton } from './styles';
import {
  TableFilters as OrganizationsFilters,
  defaultEmptyFilters as emptyFilters,
} from '../../components/Table/TableFilters';
import OrganizationsTable from './OrganizationsTable/OrganizationsTable';
import QuestionsModal from './QuestionsModal/QuestionsModal';
import { Toast } from '../../components/Toast';
import {
  formatFilterOrganizationParams,
  formatGetOrganizationsResponse,
  formatUpdateOgranizationParams,
} from '../../utilities/dataTransform';
import useModal, { ModalStates } from '../../hooks/useModal';
import useToast from '../../hooks/useToast';

function OrganizationsPage() {
  const {
    initialPaginationInfo,
    paginationInfo,
    setPageCount,
    onChange,
    resetPagination,
  } = usePagination();
  const [organizations, setOrganizations] = useState<Array<Organization>>([]);
  const [selectedOrg, setSelectedOrg] = useState<Organization | undefined>(
    undefined,
  );
  const [orgFilters, setOrgFilters] = useState(emptyFilters);
  const [questionsModalOrgId, setQuestionsModalOrgId] = useState<
    string | undefined
  >(undefined);

  const { toastConfig, closeToast, setToastConfig } = useToast();

  const closeQuestionsModal = () => {
    setQuestionsModalOrgId(undefined);
  };

  const {
    modalState, openAddModal, openEditModal, closeModal,
  } = useModal();

  const [mounted, setMounted] = useState(false);

  const fetchOrganizations = () => {
    const dataParams = formatFilterOrganizationParams(
      paginationInfo.page,
      50,
      orgFilters,
    );
    filterOrganizations(dataParams).then((response) => {
      setPageCount(response.data.number_of_pages);
      setOrganizations(formatGetOrganizationsResponse(response.data));
    });
  };

  const handleAddOrganization = (
    name: string,
    active: boolean,
    isConsOrganization: boolean,
    parentId?: string,
  ) => {
    createOrganization({
      organization_name: name,
      status: active ? 'active' : 'inactive',
      cons_organization: isConsOrganization,
      parent_id: parentId,
    })
      .then(() => {
        setToastConfig({
          variant: 'success',
          title: OrganizationToastTitles.ADD,
          isOpen: true,
        });
        fetchOrganizations();
      })
      .catch(() => {
        setToastConfig({
          variant: 'error',
          title: OrganizationToastTitles.ERROR,
          isOpen: true,
        });
      })
      .finally(() => {
        closeModal();
      });
  };

  const handleEditOrganization = (name: string, active: boolean) => {
    if (selectedOrg) {
      updateOrganization(
        formatUpdateOgranizationParams(name, active, selectedOrg),
      )
        .then(() => {
          setToastConfig({
            variant: 'success',
            title: OrganizationToastTitles.EDIT,
            isOpen: true,
          });
          fetchOrganizations();
        })
        .catch(() => {
          setToastConfig({
            variant: 'error',
            title: OrganizationToastTitles.ERROR,
            isOpen: true,
          });
        })
        .finally(() => {
          closeModal();
        });
    }
  };

  useEffect(() => {
    // we ignore initial calls for this useEffect and the one with paginationInfo.page dependency
    // this will be triggered once more after initial trigger and fetchUsers() will be called
    // this is done to avoid too much unnecessary calls on mount of component
    if (mounted) {
      const pageWillChange = paginationInfo.page !== initialPaginationInfo.page;
      resetPagination();
      // if paginationInfo.page useEffect doesn't trigger fetching, do it here manually.
      if (!pageWillChange) {
        fetchOrganizations();
      }
    } else {
      setMounted(true);
    }
  }, [orgFilters]);

  useEffect(() => {
    if (mounted) {
      fetchOrganizations();
    }
  }, [paginationInfo.page]);

  useEffect(() => {
    if (selectedOrg) {
      openEditModal();
    }
  }, [selectedOrg]);

  useEffect(() => {
    if (modalState === ModalStates.CLOSED && selectedOrg) {
      setSelectedOrg(undefined);
    }
  }, [modalState]);

  return (
    <PageContainer>
      <FiltersContainer data-testid="filtersContainer">
        <OrganizationsFilters
          onFiltersChanged={setOrgFilters}
          filterType="organization"
        />
        <AddButton variant="contained" onClick={openAddModal}>
          Add Organization
        </AddButton>
      </FiltersContainer>
      <OrganizationsTable
        organizations={organizations}
        onTableRowClicked={setSelectedOrg}
        onOpenOrgQuestions={setQuestionsModalOrgId}
      />
      {paginationInfo.pageCount > 1 ? (
        <Pagination
          data-testid="organizationsPagination"
          count={paginationInfo.pageCount}
          page={paginationInfo.page}
          onChange={onChange}
          variant="outlined"
          shape="rounded"
        />
      ) : null}
      <OrganizationActionModal
        onClose={closeModal}
        modalState={modalState}
        addOrganization={handleAddOrganization}
        editOrganization={handleEditOrganization}
        selectedOrganization={selectedOrg}
      />
      <QuestionsModal
        organizationId={questionsModalOrgId}
        onClose={closeQuestionsModal}
      />
      <Toast
        variant={toastConfig.variant}
        title={toastConfig.title}
        isOpen={toastConfig.isOpen}
        handleClose={closeToast}
      />
    </PageContainer>
  );
}

export default OrganizationsPage;
