import { toServerFormat, formatDateToLongFormat } from './dateTimeUtils';
import { Organization } from '../pages/OrganizationsPage';
import {
  FilterOrganizationsParams,
  GetOrganizationsResponse,
  UpdateOrganizationParams,
} from '../api/organization';
import { User, FullUser } from '../components/UserPage';
import { Consumer } from '../pages/ConsumersPage';
import {
  GetUserResponse,
  FilterUsersParams,
  GetUsersResponse,
  UpdateUserParams,
  GetConsumersParams,
  GetConsumersResponse,
} from '../api/user';
import {
  TestResult,
  FullTestResult,
  HealthInformationAnswer,
  ErrorLog,
} from '../pages/TestListPage';
import {
  FilterTestResultsResponse,
  FilterTestResultsParams,
  GetTestResultResponse,
  isFeedbackElementAnswer,
  isFeedbackElementSection,
} from '../api/result';
import { TableFiltersData } from '../components/Table/TableFilters';

// Organizations

export const formatFilterOrganizationParams = (
  pageNumber: number,
  pageSize: number,
  orgFilters: TableFiltersData,
): FilterOrganizationsParams => {
  const dataParams: FilterOrganizationsParams = {
    page_number: pageNumber,
    page_size: pageSize,
  };
  const { searchQuery, dateCreated, status } = orgFilters;
  dataParams.search_term = searchQuery || undefined;
  dataParams.date_from = dateCreated.start
    ? toServerFormat(dateCreated.start)
    : undefined;
  dataParams.date_to = dateCreated.end
    ? toServerFormat(dateCreated.end)
    : undefined;
  dataParams.status = status ? status.toLowerCase() : undefined;
  return dataParams;
};

export const formatUpdateOgranizationParams = (
  name: string,
  active: boolean,
  initialOrganization: Organization,
): UpdateOrganizationParams => {
  const orgName = name !== initialOrganization.orgName ? name : undefined;
  const organizationStatus = active ? 'active' : 'inactive';
  const status = organizationStatus !== initialOrganization.status
    ? organizationStatus
    : undefined;
  return {
    organization_id: initialOrganization.orgId,
    organization_name: orgName,
    status,
  };
};

export const formatGetOrganizationsResponse = (
  apiResponse: GetOrganizationsResponse,
): Array<Organization> => {
  const formattedOrganizations = apiResponse.organizations.map(
    (organization) => ({
      orgId: organization.organization_id,
      orgName: organization.organization_name,
      creationTime: organization.creation_time,
      status: organization.status,
      isConsOrganization: organization.cons_organization,
      activationCode: organization.activation_code,
      orgRelation: organization.org_relation
        ? {
          orgId: organization.org_relation.org_id,
          orgName: organization.org_relation.org_name,
        }
        : undefined,
    }),
  );
  return formattedOrganizations;
};

// Users

export const formatFilterUserParams = (
  pageNumber: number,
  pageSize: number,
  role: 'hcp' | 'admin',
  userFilters: TableFiltersData,
): FilterUsersParams => {
  const dataParams: FilterUsersParams = {
    page_number: pageNumber,
    page_size: pageSize,
    role,
  };
  const { searchQuery, dateCreated, status } = userFilters;
  dataParams.search_term = searchQuery || undefined;
  dataParams.date_from = dateCreated.start
    ? toServerFormat(dateCreated.start)
    : undefined;
  dataParams.date_to = dateCreated.end
    ? toServerFormat(dateCreated.end)
    : undefined;
  dataParams.status = status ? status.toLowerCase() : undefined;
  return dataParams;
};

export const formatUpdateUserParams = (
  orgId: string,
  name: string,
  status: 'active' | 'inactive',
  initialUser: FullUser,
): UpdateUserParams => {
  const userName = name !== initialUser.name ? name : undefined;
  const userStatus = status !== initialUser.status ? status : undefined;
  const userOrgId = orgId !== initialUser.orgId ? orgId : undefined;
  return {
    user_id: initialUser.userId,
    organization_id: userOrgId,
    name: userName,
    status: userStatus,
  };
};

export const formatGetUsersResponse = (
  apiResponse: GetUsersResponse,
): Array<User> => {
  const formattedUsers: Array<User> = apiResponse.users.map((user) => ({
    userId: user.user_id,
    name: user.name,
    orgName: user.organization_name,
    orgId: user.organization_id,
    creationTime: user.creation_time,
    email: user.user_email,
    status: user.status,
  }));
  return formattedUsers;
};

export const formatGetUserResponse = (
  apiResponse: GetUserResponse,
): FullUser => ({
  userId: apiResponse.user_id,
  name: apiResponse.name,
  orgName: apiResponse.organization_name,
  orgId: apiResponse.organization_id,
  orgStatus: apiResponse.organization_status,
  creationTime: apiResponse.creation_time,
  email: apiResponse.user_email,
  status: apiResponse.status,
  enabled: apiResponse.enabled,
  userStatus: apiResponse.user_status,
});

export const formatGetConsumersParams = (
  pageNumber: number,
  pageSize: number,
  consumerFilters: TableFiltersData,
): GetConsumersParams => {
  const dataParams: GetConsumersParams = {
    page_number: pageNumber,
    page_size: pageSize,
  };
  const { searchQuery, dateCreated, dateLastUsed } = consumerFilters;
  dataParams.search_term = searchQuery || undefined;
  dataParams.date_from = dateCreated.start
    ? toServerFormat(dateCreated.start)
    : undefined;
  dataParams.date_to = dateCreated.end
    ? toServerFormat(dateCreated.end)
    : undefined;
  dataParams.last_used_from = dateLastUsed?.start
    ? toServerFormat(dateLastUsed.start)
    : undefined;
  dataParams.last_used_to = dateLastUsed?.end
    ? toServerFormat(dateLastUsed.end)
    : undefined;
  return dataParams;
};

export const formatGetConsumersResponse = (
  apiResponse: GetConsumersResponse,
): Array<Consumer> => {
  const formattedConsumers: Array<Consumer> = apiResponse.consumers.map(
    (consumer) => ({
      userEmail: consumer.user_email,
      name: consumer.name,
      dateCreated: consumer.date_created,
      lastTimeUsed: consumer.last_time_used,
    }),
  );
  return formattedConsumers;
};

// Test Results

export const formatFilterTestResultsResponse = (
  apiResponse: FilterTestResultsResponse,
): Array<TestResult> => {
  const formattedTestResults: Array<TestResult> = apiResponse.results.map(
    (result) => ({
      testId: result.test_id,
      resultId: result.result_id,
      organization: result.organization,
      testType: result.test_type,
      hcpName: result.hcp_name,
      hcpEmail: result.hcp_email,
      deviceId: result.device_id,
      lfdLot: result.lfd_lot,
      status: result.status,
      dateCreated: result.date_created,
    }),
  );
  return formattedTestResults;
};

export const formatFilterTestResultsParams = (
  pageNumber: number,
  pageSize: number,
  testResultsFilters: TableFiltersData,
): FilterTestResultsParams => {
  const dataParams: FilterTestResultsParams = {
    page_number: pageNumber,
    page_size: pageSize,
  };
  const { searchQuery, dateCreated, status } = testResultsFilters;
  dataParams.search_term = searchQuery || undefined;
  dataParams.date_from = dateCreated.start
    ? toServerFormat(dateCreated.start)
    : undefined;
  dataParams.date_to = dateCreated.end
    ? toServerFormat(dateCreated.end)
    : undefined;
  const statuses = status !== '' ? status.toLowerCase().split(', ') : [];
  dataParams.statuses = statuses.length ? statuses : undefined;
  return dataParams;
};

/**
 * returns string value of number if it exists, otherwise placeholder
 * @param numValue nullable number value
 * @param placeholder
 */

const numberToString = (
  numValue: number | null | undefined,
  placeholder: string,
): string => (numValue ? numValue.toString() : placeholder);

export const formatTestResultResponse = ({
  subject_data,
  results,
  error_logs,
}: GetTestResultResponse): FullTestResult => {
  const formattedErrorLogs: Array<ErrorLog> = error_logs.map((errorLog) => ({
    errorId: errorLog.error_id,
    errorType: errorLog.error_type,
    createdAt: errorLog.created_at,
    imgCapturedAt: errorLog.img_captured_at,
    timerStartedAt: errorLog.timer_started_at,
    testSubmittedAt: errorLog.test_submitted_at,
    images: errorLog.images,
  }));
  const formattedAnswers: Array<HealthInformationAnswer> = subject_data.answers.map((answer) => ({
    label: answer.question_content,
    value: answer.answer_content,
    riskFactor: answer.risk_factor ?? 1,
    influencesBiomarkers: answer.influences_biomarkers,
  }));
  const formattedPersonalAnswers = subject_data.personal_answers.map(
    (answer) => ({
      label: answer.question_content,
      value: answer.answer_content,
      questionTitle: answer.question_title,
    }),
  );
  const formattedFeedbackAnswers = subject_data.feedback_answers.map(
    (answer) => {
      if (isFeedbackElementAnswer(answer)) {
        return {
          label: answer.question_content,
          value: answer.answer_content,
          questionTitle: answer.question_title,
        };
      }
      if (isFeedbackElementSection(answer)) {
        return {
          sectionLabel: answer.section_label,
          answers: answer.answers.map((nestedAnswer) => ({
            label: nestedAnswer.question_content,
            value: nestedAnswer.answer_content,
            questionTitle: nestedAnswer.question_title,
          })),
        };
      }
      return { label: '', value: '', questionTitle: '' };
    },
  );
  const {
    device_platform,
    device_model,
    app_ver,
    algo_ver,
    lfd_lot,
    test_started_at,
    timer_started_at,
    test_submitted_at,
    image_captured_at,
    results_created_at,
  } = results.metadata;
  const formattedMetadata = [
    { label: 'OS Version', value: device_platform },
    { label: 'App Version', value: app_ver },
    { label: 'Pipeline Version', value: algo_ver || '-' },
    { label: 'Device Model', value: device_model },
    { label: 'Lot Number', value: lfd_lot ?? '-' },
    {
      label: 'Start of the Test',
      value: formatDateToLongFormat(new Date(test_started_at)),
    },
    {
      label: 'Start of the Timer',
      value: formatDateToLongFormat(new Date(timer_started_at)),
    },
    {
      label: 'Image Captured',
      value: image_captured_at ? formatDateToLongFormat(new Date(image_captured_at)) : '-',
    },
    {
      label: 'Test Submission',
      value: formatDateToLongFormat(new Date(test_submitted_at)),
    },
    {
      label: 'Test Results Created',
      value: formatDateToLongFormat(new Date(results_created_at)),
    },
  ];

  return {
    subjectData: {
      testId: subject_data.test_id,
      createdAt: subject_data.created_at,
      testStartedAt: subject_data.test_started_at,
      subjectEmail: subject_data.subject_email,
      newsAndAnnouncements: subject_data.news_and_announcements,
      bmi: subject_data.bmi,
      answers: formattedAnswers,
      personalAnswers: formattedPersonalAnswers,
      feedbackAnswers: formattedFeedbackAnswers,
      nhsNumber: subject_data.nhs_number,
      odsCode: subject_data.ods_code,
    },
    results: {
      status: results.status,
      resultData: {
        l: {
          triglycerides: numberToString(results.tg_l, '--'),
          hdl: numberToString(results.hdl_l, '--'),
          totalCholesterol: numberToString(results.tc_l, '--'),
          nonHdl: '--',
          tcHdlRatio: '--',
        },
        a: {
          triglycerides: numberToString(results.tg_a, '--'),
          hdl: numberToString(results.hdl_a, '--'),
          totalCholesterol: numberToString(results.tc_a, '--'),
          nonHdl: '--',
          tcHdlRatio: '--',
        },
        b: {
          triglycerides: numberToString(results.tg_b, '--'),
          hdl: numberToString(results.hdl_b, '--'),
          totalCholesterol: numberToString(results.tc_b, '--'),
          nonHdl: '--',
          tcHdlRatio: '--',
        },
        rawValues: {
          triglycerides: numberToString(results.tg_raw, '--'),
          hdl: numberToString(results.hdl_raw, '--'),
          totalCholesterol: numberToString(results.tc_raw, '--'),
          nonHdl: '--',
          tcHdlRatio: '--',
        },
        publishedValues: {
          triglycerides: numberToString(results.triglycerides, '--'),
          hdl: numberToString(results.hdl, '--'),
          totalCholesterol: numberToString(results.total_cholesterol, '--'),
          nonHdl: numberToString(results.non_hdl, '--'),
          tcHdlRatio: numberToString(results.total_hdl_ratio, '--'),
        },
      },
      signalMeasurements: {
        overNoise: {
          hdl: results.signal_measurements.hdl.over_noise,
          triglycerides: results.signal_measurements.tg.over_noise,
          totalCholesterol: results.signal_measurements.tc.over_noise,
        },
        amplitude: {
          hdl: results.signal_measurements.hdl.amplitude,
          triglycerides: results.signal_measurements.tg.amplitude,
          totalCholesterol: results.signal_measurements.tc.amplitude,
        },
        bandwidth: {
          hdl: results.signal_measurements.hdl.bandwidth,
          triglycerides: results.signal_measurements.tg.bandwidth,
          totalCholesterol: results.signal_measurements.tc.bandwidth,
        },
        meanDeltaE: results.signal_measurements.mean_delta_e,
        medianDeltaE: results.signal_measurements.median_delta_e,
      },
      pdfUrl: results.pdf_url,
      pdfPass: results.pdf_pass,
      pdfName: results.pdf_name,
      totalCholesterol: {
        value: results.total_cholesterol,
        thresholdExceeded: results.tc_thold_exceeded,
      },
      hdl: {
        value: results.hdl,
        thresholdExceeded: results.hdl_thold_exceeded,
      },
      nonHdl: {
        value: results.non_hdl,
        thresholdExceeded: results.non_hdl_thold_exceeded,
      },
      ldl: {
        value: results.ldl,
        thresholdExceeded: results.ldl_thold_exceeded,
      },
      totalHdlRatio: {
        value: results.total_hdl_ratio,
        thresholdExceeded: results.tc_hdl_ratio_thold_exceeded,
      },
      triglycerides: {
        value: results.triglycerides,
        thresholdExceeded: results.tg_thold_exceeded,
      },
      warningMessage: results.warning_msg,
      images: {
        barcodesAndRois: results.images['barcodes&rois'],
        colorCorrected: results.images.color_corrected,
        final: results.images.final,
        inputCropped: results.images.input_cropped,
        shadeCorrected: results.images.shade_corrected,
        whiteBalanced: results.images.white_balanced,
        linearized: results.images.linearized,
        original: results.images.original,
        rois: results.images.rois,
        sitesBinaryMask: results.images.sites_binary_mask,
      },
      metadata: formattedMetadata,
      qrisk: {
        heartAge: results.qrisk.qrisk_healthy_heart_age,
        publishedHeartAge: results.qrisk.published_qrisk_healthy_heart_age,
        scoreHealthy: results.qrisk.qrisk_score_healthy_person,
        publishedScoreHealthy: results.qrisk.published_qrisk_score_healthy_person,
        score10Year: results.qrisk.qrisk_score_10_year,
        publishedScore10Year: results.qrisk.published_qrisk_score_10_year,
        status: results.qrisk.qrisk_status,
      },
    },
    errorLogs: formattedErrorLogs,
  };
};
