import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { useForm, Controller } from 'react-hook-form';
import { Typography, Box } from '@mui/material';
import Colors from '../../../theme/colors';
import { OrganizationPicker } from '../../../components/OrganizationPicker';
import {
  OrganizationActionModalProps,
  orgActionModalInputRules,
  consumerInfoItems,
  hcpInfoItems,
  OrgActionModalFormFields,
  defaultFieldValues,
  NAME_UNAVAILABLE,
} from '.';
import { ModalStates } from '../../../hooks/useModal';
import { Organization } from '..';
import { UserOrganization } from '../../../components/UserPage';
import {
  ModalActionsHandler,
  ModalTextInput,
  ModalSwitchInput,
} from '../../../components/Modal';
import { RadioButtons } from '../../../components/RadioButtons';

import { getOrgNameAvailability } from '../../../api/organization/organization';
import {
  ContentContainer,
  InputFieldsContainer,
  HCPConsFieldContainer,
  ErrorText,
} from './styles';

function OrganizationActionModal({
  onClose,
  modalState,
  addOrganization,
  editOrganization,
  selectedOrganization,
}: OrganizationActionModalProps) {
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const {
    handleSubmit,
    control,
    reset,
    watch,
    setError,
    formState: { isDirty, dirtyFields },
  } = useForm({
    defaultValues: defaultFieldValues,
  });

  const isOpen = modalState !== ModalStates.CLOSED;

  const organizationType = watch('organizationType', '');
  const [organizations, setOrganizations] = useState<Array<Organization>>([]);
  const [selectedOrg, setSelectedOrg] = useState<
    UserOrganization | undefined
  >();

  useEffect(() => {
    switch (modalState) {
      case ModalStates.EDIT: {
        if (selectedOrganization) {
          const { status, orgName, isConsOrganization } = selectedOrganization;
          reset({
            active: status === 'active',
            name: orgName,
            organizationType: isConsOrganization ? 'Consumer' : 'HCP',
          });
        }
        break;
      }
      case ModalStates.ADD: {
        reset(defaultFieldValues);
        break;
      }
      default: {
        setSubmitDisabled(false);
        setSelectedOrg(undefined);
      }
    }
  }, [modalState]);

  const handleEditOrganization = ({
    name,
    active,
  }: OrgActionModalFormFields) => {
    if (isDirty) {
      editOrganization(name, active);
    } else {
      onClose();
    }
  };

  const onSubmit = (data: OrgActionModalFormFields) => {
    if (modalState === ModalStates.EDIT) {
      handleEditOrganization(data);
    } else {
      addOrganization(
        data.name,
        data.active,
        data.organizationType === 'Consumer',
        selectedOrg?.orgId,
      );
    }
  };

  const checkNameAvailability = (data: OrgActionModalFormFields) => {
    setSubmitDisabled(true);
    if (modalState === ModalStates.ADD || dirtyFields.name) {
      getOrgNameAvailability({ organization_name: data.name })
        .then(() => {
          setError('name', { type: 'custom', message: NAME_UNAVAILABLE });
          setSubmitDisabled(false);
        })
        .catch((error) => {
          if (error.status === 404) {
            onSubmit(data);
          }
        });
    } else {
      onSubmit(data);
    }
  };

  const getInfoItems = () => {
    switch (organizationType) {
      case 'Consumer': {
        return consumerInfoItems;
      }
      case 'HCP': {
        return hcpInfoItems;
      }
      default:
        return [];
    }
  };

  const onSetOrganizations = (formattedOrganizations: Array<Organization>) => {
    setOrganizations((prev) => [...prev, ...formattedOrganizations]);
  };

  return (
    <Dialog onClose={onClose} open={isOpen}>
      <ContentContainer>
        <InputFieldsContainer>
          {modalState === ModalStates.ADD ? (
            <>
              <Controller
                name="organizationType"
                control={control}
                rules={orgActionModalInputRules.organizationType}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <HCPConsFieldContainer>
                    <Typography
                      variant="BodyMediumRegular"
                      color={Colors.Text.Paragraph}
                    >
                      Organization Type
                    </Typography>
                    <Box>
                      <RadioButtons
                        selectedValue={value}
                        values={['HCP', 'Consumer']}
                        onSelect={onChange}
                      />
                      {error?.message ? (
                        <ErrorText>{error?.message}</ErrorText>
                      ) : null}
                    </Box>
                  </HCPConsFieldContainer>
                )}
              />
              <OrganizationPicker
                name="parentId"
                control={control}
                label="Parent HCP"
                showPicker={organizationType === 'Consumer'}
                organizations={organizations}
                selectedOrg={selectedOrg}
                setSelectedOrg={setSelectedOrg}
                setOrganizations={onSetOrganizations}
              />
            </>
          ) : null}

          <ModalTextInput
            label="Organization"
            inputProps={{
              name: 'name',
              control,
              rules: orgActionModalInputRules.name,
              label: 'Name',
              autoComplete: 'new-password',
            }}
          />
          <Controller
            name="active"
            control={control}
            render={({ field: { onChange, value } }) => (
              <ModalSwitchInput
                label="Status"
                checked={value}
                handleChange={onChange}
              />
            )}
          />
        </InputFieldsContainer>
        <ModalActionsHandler
          modalState={modalState}
          submitDisabled={submitDisabled}
          infoItems={getInfoItems()}
          handleSubmit={handleSubmit(checkNameAvailability)}
          handleClose={onClose}
        />
      </ContentContainer>
    </Dialog>
  );
}

export default OrganizationActionModal;
