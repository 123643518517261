import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Colors from '../../../theme/colors';

const TestResultDialog = styled(Dialog)({
  marginY: '32px',
});

const ContentContainer = styled(Box)({
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '32px',
  paddingRight: '32px',
  paddingTop: '48px',
  paddingBottom: '100px',
});

const TitleContainer = styled(Box)({
  paddingLeft: '8px',
  display: 'flex',
  flexDirection: 'row',
});

const Title = styled(Typography)({ marginRight: '24px' });

const TestDetailsContainer = styled(Box)({
  paddingLeft: '8px',
  marginTop: '24px',
  marginBottom: '32px',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
});

const TestDetailsRowContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const TestId = styled(Typography)({ marginRight: '72px', marginLeft: '16px' });

TestId.defaultProps = {
  variant: 'BodySmallRegular',
  color: Colors.Text.Paragraph,
};

const Date = styled(Typography)({ marginLeft: '16px' });

Date.defaultProps = {
  variant: 'BodySmallRegular',
  color: Colors.Text.Paragraph,
};

const PdfContainer = styled(Box)({ display: 'flex', flexDirection: 'row' });

const PdfLabel = styled(Typography)({ marginRight: '16px' });

PdfLabel.defaultProps = {
  variant: 'BodySmallSemiBold',
  color: Colors.Text.Heading,
};

const PdfLink = styled(Link)({});

PdfLink.defaultProps = {
  variant: 'BodySmallRegular',
  underline: 'none',
};

const PdfPass = styled(Typography)({});
PdfPass.defaultProps = {
  variant: 'BodySmallRegular',
};

const TestDetailsItemLarge = styled(Box)({
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'baseline',
  width: '50%',
});

const TestDetailsItem = styled(Box)({
  flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'baseline', width: '50%',
});

const TestStatusContainer = styled(Box)({
  flexDirection: 'row', marginLeft: '16px', display: 'flex', alignItems: 'center',
});

const StatusIndicator = styled(Box)({
  borderRadius: '10px', height: '10px', width: '10px', marginRight: '8px',
});

const PersonalDataContainer = styled(Box)({
  marginTop: '4px',
  marginBottom: '4px',
});

const ErrorLogsContainer = styled(Box)({
  marginTop: '4px',
  marginBottom: '4px',
});

const FeedbackAnswersContainer = styled(Box)({
  marginTop: '4px',
  marginBottom: '4px',
});

const QriskContainer = styled(Box)({
  marginTop: '4px',
  marginBottom: '4px',
});

const AnsweredQuestionsContainer = styled(Box)({
  marginTop: '4px',
  marginBottom: '4px',
});

const TestImagesContainer = styled(Box)({
  marginTop: '4px',
  marginBottom: '4px',
});

const TestMetadataContainer = styled(Box)({
  marginTop: '4px',
  marginBottom: '4px',
});

const ResultDataContainer = styled(Box)({
  marginTop: '4px',
  marginBottom: '4px',
});

const SignalMeasurementsContainer = styled(Box)({
  marginTop: '4px',
  marginBottom: '100px',
});

const CloseButtonContainer = styled(Box)({
  width: '60%',
  paddingBottom: '100px',
  marginLeft: '20%',
});

const WarningContainer = styled(Box)({
  paddingLeft: '8px',
  display: 'flex',
  flexDirection: 'row',
  marginTop: '12px',
  alignItems: 'center',
});

const WarningMessage = styled(Typography)({ marginLeft: '8px' });

const CloseButton = styled(Button)({ width: '100%' });

const InfoFieldLabel = styled(Typography)({});

InfoFieldLabel.defaultProps = {
  variant: 'BodySmallSemiBold',
  color: Colors.Text.Heading,
};

export {
  TestResultDialog,
  ContentContainer,
  TitleContainer,
  Title,
  TestDetailsContainer,
  TestDetailsRowContainer,
  TestId,
  Date,
  PdfContainer,
  PdfLabel,
  PdfLink,
  PdfPass,
  PersonalDataContainer,
  ErrorLogsContainer,
  FeedbackAnswersContainer,
  QriskContainer,
  AnsweredQuestionsContainer,
  TestImagesContainer,
  TestDetailsItemLarge,
  TestDetailsItem,
  TestStatusContainer,
  StatusIndicator,
  TestMetadataContainer,
  ResultDataContainer,
  SignalMeasurementsContainer,
  CloseButtonContainer,
  WarningContainer,
  WarningMessage,
  CloseButton,
  InfoFieldLabel,
};
